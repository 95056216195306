





























































































import { tareaAllDto } from "@/shared/dtos/tareas/tareaAllDto";
import { MessageService } from "@/shared/services/message-service";
import tareaModule from "@/store/modules/tarea-module";
import { Component, PropSync, Vue } from "vue-property-decorator";
@Component({
  components: {
    TareaFormulario: () =>
      import("@/views/proyectos/tareas/tareasFormulario.vue"),
    KanbanList: () => import("@/components/TypeView/Kanban_List_Gantt.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
})
export default class TareaLista extends Vue {
  @PropSync("id_proyecto", { default: -1 }) id_proyectosync!: number;
  @PropSync("tareas", { default: [] })
  tareas_list!: tareaAllDto[];

  public show_dialog_tarea: boolean = false;
  public id_tarea: number = -1;

  created() {
    this.GetTareas();
  }

  public get tareas() {
    return tareaModule.tareas_proyecto;
  }

  public GetTareas() {
    if (this.id_proyectosync === -1) {
      return tareaModule.gettareaes_completo();
    } else {
      return tareaModule.gettarea_proyecto(this.id_proyectosync);
    }
  }

  editar(obj: tareaAllDto) {
    this.id_tarea = obj.id;
    this.show_dialog_tarea = true;
  }

  public close_dialog() {
    this.show_dialog_tarea = false;
    this.GetTareas().then((x: tareaAllDto[]) => (this.tareas_list = x));
    this.id_tarea = -1;
  }

  eliminar(obj: tareaAllDto) {
    MessageService.confirm(
      "¿Seguro que desea eliminar la tarea?",
      "Eliminar",
      (cb: boolean) => {
        if (cb) {
          tareaModule.eliminartarea(obj).then(() => {
            this.close_dialog();
          });
        }
      }
    );
  }
}
